<nav class="menu-wrapper" [ngClass]="{ 'open-all': isMenuOpen }">
    <div class="outer" (click)="closeMenu()"></div>
    <div class="scroller">
        <ul class="menu">
            <li
                [ngClass]="{
                    open: isMenuOpen,
                    active: this.router.url.startsWith('/myceres/settings') && !isMenuOpen,
                    extend: isMenuOpen
                }"
            >
                <a routerLink="/myceres/settings" routerLinkActive="active" class="d-flex align-items-center">
                    @if (appUserService.loggedInUser$ | async) {
                        <div class="avatar-placeholder d-flex align-items-center justify-content-center">
                            {{ currentUser ? currentUser.nameShort : '' }}
                        </div>
                    }
                </a>
                <ul class="submenu-avatar">
                    <li
                        [ngClass]="{ avatarOpen: isMenuOpen }"
                        (click)="closeMenu()"
                        routerLink="/myceres/settings"
                        routerLinkActive="active"
                    >
                        <p class="employee-name">{{ currentUser ? currentUser.name : '' }}</p>
                        <p class="employee-department">
                            {{ currentUser ? '(' + currentUser.details.department + ')' : '' }}
                        </p>
                    </li>
                </ul>
            </li>

            <!-- home -->
            <li [ngClass]="{ active: this.router.url === '/' }">
                <a routerLink="/" routerLinkActive="active" class="d-flex align-items-center" (click)="closeMenu()">
                    <i
                        class="icon a-icon a-icon--home"
                        [matTooltip]="'shared.miscellaneous.home' | transloco"
                        [matTooltipPosition]="'right'"
                        matTooltipClass="pwo-tooltip"
                    ></i
                    >{{ 'shared.miscellaneous.home' | transloco }}
                </a>
            </li>

            @if (appUserService.hasPermission(permission.task)) {
                <li [ngClass]="{ active: this.router.url.startsWith('/tasks') }">
                    <a
                        routerLink="/tasks"
                        routerLinkActive="active"
                        class="d-flex align-items-center"
                        (click)="closeMenu()"
                    >
                        <i
                            class="icon a-icon a-icon--edit"
                            [matTooltip]="isMenuOpen ? '' : ('my-ceres.general.tasks-title' | transloco)"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="pwo-tooltip"
                        ></i
                        >{{ 'my-ceres.general.tasks-title' | transloco }}
                    </a>
                </li>
            }
            <!-- business partner -->
            <ng-container *ceresRequiredFeatures="[features.businessPartner.enabled]">
                @if (!(appUserService.isHoursTracker() || appUserService.isUser())) {
                    <li [ngClass]="{ active: this.router.url.startsWith('/businesspartner') }">
                        <a
                            routerLink="/businesspartner"
                            routerLinkActive="active"
                            class="d-flex align-items-center"
                            (click)="closeMenu()"
                        >
                            <i
                                class="icon a-icon a-icon--forum"
                                [matTooltip]="isMenuOpen ? '' : ('businesspartner.general.title' | transloco)"
                                [matTooltipPosition]="'right'"
                                matTooltipClass="pwo-tooltip"
                            ></i
                            >{{ 'businesspartner.general.title' | transloco }}
                        </a>
                    </li>
                }
            </ng-container>

            <!-- sales -->
            <ng-container *ceresRequiredFeatures="[features.sales.enabled]">
                @if (
                    appUserService.hasAnyPermission([
                        permission.bop.readAll,
                        permission.salesActivity.readAll,
                        permission.contract.readAll,
                        permission.controlling.salesFunnel
                    ])
                ) {
                    <li
                        [ngClass]="{
                            open: nestedNavigationMenus[0].open === true && isMenuOpen,
                            active:
                                (this.router.url.startsWith('/sales-activity') ||
                                    this.router.url.startsWith('/bop') ||
                                    this.router.url.startsWith('/controlling/sales-funnel') ||
                                    this.router.url.startsWith('/contract')) &&
                                !isMenuOpen
                        }"
                    >
                        <a (click)="openSubMenu($event, 0)">
                            <i
                                class="icon a-icon a-icon--investor-relations"
                                [matTooltip]="isMenuOpen ? '' : ('sales-activity.general.sales-title' | transloco)"
                                [matTooltipPosition]="'right'"
                                matTooltipClass="pwo-tooltip"
                            ></i
                            >{{ 'sales-activity.general.sales-title' | transloco }}
                            <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
                            <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
                        </a>
                        <ul class="submenu" [ngClass]="{ open: nestedNavigationMenus[0].open === true }">
                            <ng-container *ceresRequiredFeatures="[features.sales.businessOpportunities]">
                                @if (appUserService.hasPermission(permission.bop.readAll)) {
                                    <li (click)="closeMenu()" routerLink="/bop" routerLinkActive="active">
                                        <a>{{ 'bop.general.bop-title' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                            <ng-container *ceresRequiredFeatures="[features.sales.salesActivities]">
                                @if (appUserService.hasPermission(permission.salesActivity.readAll)) {
                                    <li (click)="closeMenu()" routerLink="/sales-activity" routerLinkActive="active">
                                        <a>{{ 'sales-activity.general.title' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                            <ng-container *ceresRequiredFeatures="[features.sales.salesFunnel]">
                                @if (appUserService.hasPermission(permission.controlling.salesFunnel)) {
                                    <li
                                        (click)="closeMenu()"
                                        routerLink="/controlling/sales-funnel"
                                        routerLinkActive="active"
                                    >
                                        <a>{{ 'salesfunnel.general.title' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                            <ng-container *ceresRequiredFeatures="[features.sales.offers]">
                                @if (appUserService.hasPermission(permission.contract.readAll)) {
                                    <li (click)="closeMenu()" routerLink="/contract" routerLinkActive="active">
                                        <a>{{ 'offers.general.title' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                        </ul>
                    </li>
                }
            </ng-container>

            <!-- projects -->
            <ng-container *ceresRequiredFeatures="[features.projects.enabled]">
                <li
                    [ngClass]="{
                        open: nestedNavigationMenus[1].open === true && isMenuOpen,
                        active:
                            ((this.router.url.startsWith('/projects') && this.router.url !== '/projects/favorites/') ||
                                this.router.url.includes('/accounting/overview') ||
                                this.router.url.startsWith('/accounting/merchant') ||
                                this.router.url.startsWith('/projects/extern')) &&
                            !isMenuOpen
                    }"
                >
                    @if (appUserService.hasAnyPermission([permission.project.readAll, permission.project.readOwn])) {
                        <a (click)="openSubMenu($event, 1)">
                            <i
                                class="icon a-icon a-icon--feature"
                                [matTooltip]="isMenuOpen ? '' : ('projects.general.title-projects' | transloco)"
                                [matTooltipPosition]="'right'"
                                matTooltipClass="pwo-tooltip"
                            ></i
                            >{{ 'projects.general.title-projects' | transloco }}
                            <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
                            <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
                        </a>
                    }
                    <ul class="submenu" [ngClass]="{ open: nestedNavigationMenus[1].open === true }">
                        <li
                            (click)="closeMenu()"
                            [routerLinkActiveOptions]="{ exact: true }"
                            routerLink="/projects"
                            routerLinkActive="active"
                        >
                            <a>{{ 'projects.general.title-projects' | transloco }}</a>
                        </li>
                        @if (appUserService.hasPermission(permission.project.readAll)) {
                            <ng-container *ceresRequiredFeatures="[features.projects.groups]">
                                <li
                                    (click)="closeMenu()"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    routerLink="/projectgroup"
                                    routerLinkActive="active"
                                >
                                    <a>{{ 'projects.project-properties.project-group' | transloco }}</a>
                                </li>
                            </ng-container>
                            <ng-container *ceresRequiredFeatures="[features.projects.charging]">
                                @if (!appUserService.isMerchant()) {
                                    <li
                                        (click)="closeMenu()"
                                        routerLink="/accounting/overview"
                                        routerLinkActive="active"
                                    >
                                        <a>{{ 'projects.general.title-accounting' | transloco }}</a>
                                    </li>
                                }
                                @if (appUserService.isMerchant()) {
                                    <li
                                        (click)="closeMenu()"
                                        routerLink="/accounting/merchant"
                                        routerLinkActive="active"
                                    >
                                        <a>{{ 'projects.general.title-accounting' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                            <!-- <li
            *ngIf="!appUserService.isHoursTracker && !appUserService.isTeamMember()"
            (click)="closeMenu()"
            [routerLinkActiveOptions]="{ exact: true }"
            routerLink="/projects/extern"
            routerLinkActive="active"
            >
            <a>{{ 'projects.project-external-costs.title' | transloco }}</a>
          </li> -->
                            <ng-container *ceresRequiredFeatures="[features.projects.nrsView]">
                                @if (appUserService.hasPermission(permission.project.nrs)) {
                                    <li
                                        (click)="closeMenu()"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        routerLink="/projects/nrs"
                                        routerLinkActive="active"
                                    >
                                        <a>{{ 'projects.project-nrs-view.title' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                        }
                        @if (appUserService.hasPermission(permission.project.preStaffing.readAll)) {
                            <ng-container *ceresRequiredFeatures="[features.projects.preStaffing]">
                                <li
                                    (click)="closeMenu()"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    routerLink="/prestaffing"
                                    routerLinkActive="active"
                                >
                                    <a>{{ 'projects.pre-staffing.title' | transloco }}</a>
                                </li>
                            </ng-container>
                        }
                    </ul>
                </li>
            </ng-container>

            <!-- Time management -->
            <ng-container *ceresRequiredFeatures="[features.timeManagement.enabled]">
                @if (
                    appUserService.hasAnyPermission([
                        permission.timesheet,
                        permission.absences,
                        permission.workload.readAll,
                        permission.capacities
                    ])
                ) {
                    <li
                        [ngClass]="{
                            open: nestedNavigationMenus[2].open === true && isMenuOpen,
                            active:
                                (this.router.url.startsWith('/timesheet') ||
                                    this.router.url.startsWith('/capacities') ||
                                    this.router.url.startsWith('/absences') ||
                                    this.router.url.startsWith('/workload') ||
                                    this.router.url.startsWith('/projects/favorites')) &&
                                !isMenuOpen
                        }"
                    >
                        <a (click)="openSubMenu($event, 2)">
                            <i
                                class="icon a-icon a-icon--clock"
                                [matTooltip]="isMenuOpen ? '' : ('time-management.general.title' | transloco)"
                                [matTooltipPosition]="'right'"
                                matTooltipClass="pwo-tooltip"
                            ></i
                            >{{ 'time-management.general.title' | transloco }}
                            <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
                            <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
                        </a>
                        <ul class="submenu" [ngClass]="{ open: nestedNavigationMenus[2].open === true }">
                            <ng-container *ceresRequiredFeatures="[this.features.timeManagement.timeSheets]">
                                @if (appUserService.hasPermission(permission.timesheet)) {
                                    <li (click)="closeMenu()" routerLink="/timesheet" routerLinkActive="active">
                                        <a>{{ 'time-management.general.time-sheets-title' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                            <ng-container *ceresRequiredFeatures="[this.features.timeManagement.capacity]">
                                @if (appUserService.hasPermission(permission.capacities)) {
                                    <li (click)="closeMenu()" routerLink="/capacities" routerLinkActive="active">
                                        <a>{{ 'time-management.general.capacity-planning-title' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                            <ng-container *ceresRequiredFeatures="[this.features.timeManagement.absences]">
                                @if (appUserService.hasPermission(permission.absences)) {
                                    <li (click)="closeMenu()" routerLink="/absences" routerLinkActive="active">
                                        <a>{{ 'time-management.general.absences-title' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                            <ng-container *ceresRequiredFeatures="[this.features.timeManagement.workload]">
                                @if (appUserService.hasPermission(permission.workload.readAll)) {
                                    <li (click)="closeMenu()" routerLink="/workload" routerLinkActive="active">
                                        <a>{{ 'time-management.general.workload-title' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                            <ng-container
                                *ceresRequiredFeatures="[this.features.timeManagement.transactionalTimeTracking]"
                            >
                                @if (appUserService.hasPermission(permission.workload.readAll)) {
                                    <li
                                        (click)="closeMenu()"
                                        routerLink="/transactional-hours"
                                        routerLinkActive="active"
                                    >
                                        <a>{{ 'time-management.general.transactional-hours-title' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                        </ul>
                    </li>
                }
            </ng-container>
            <!--Reporting-->
            <ng-container *ceresRequiredFeatures="[features.reporting.enabled]">
                @if (appUserService.hasPermission(permission.reports)) {
                    <li
                        [ngClass]="{
                            open: nestedNavigationMenus[3].open === true && isMenuOpen,
                            active:
                                (this.router.url.startsWith('/controlling') ||
                                    this.router.url.startsWith('/reports') ||
                                    this.router.url.startsWith('/cost-report') ||
                                    this.router.url.startsWith('/working-hours-report') ||
                                    this.router.url === '/controlling' ||
                                    this.router.url.startsWith('/gmsreporting') ||
                                    this.router.url.startsWith('/controlling/quality-projects')) &&
                                !isMenuOpen
                        }"
                    >
                        <a (click)="openSubMenu($event, 3)">
                            <i
                                class="icon a-icon a-icon--control"
                                [matTooltip]="'reporting.general.title' | transloco"
                                [matTooltipPosition]="'right'"
                                matTooltipClass="pwo-tooltip"
                            ></i
                            >{{ 'reporting.general.title' | transloco }}
                            <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
                            <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
                        </a>
                        <ul class="submenu" [ngClass]="{ open: nestedNavigationMenus[3].open === true }">
                            <ng-container *ceresRequiredFeatures="[features.reporting.dataQuality]">
                                @if (appUserService.hasPermission(permission.controlling.quality)) {
                                    <li
                                        (click)="closeMenu()"
                                        routerLink="/controlling/quality-projects"
                                        routerLinkActive="active"
                                    >
                                        <a>{{ 'reporting.general.data-quality-title' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                            <ng-container *ceresRequiredFeatures="[features.reporting.pulseDashboard]">
                                <li>
                                    @if (appUserService.hasPermission(permission.reports)) {
                                        <a
                                            (click)="closeMenu()"
                                            href="https://siemens.pulse.cloud/project/178/group/349/dash/53431"
                                            target="_blank"
                                        >
                                            {{ 'reporting.general.pulse-title' | transloco }}
                                        </a>
                                    }
                                </li>
                            </ng-container>
                            <ng-container *ceresRequiredFeatures="[features.reporting.costReport]">
                                @if (appUserService.hasPermission(permission.costReport)) {
                                    <li (click)="closeMenu()" routerLink="/cost-report" routerLinkActive="active">
                                        <a class="icon nav-icon icon-myceres">{{
                                            'reporting.general.cost-report-title' | transloco
                                        }}</a>
                                    </li>
                                }
                            </ng-container>
                            <ng-container *ceresRequiredFeatures="[features.reporting.workingHoursReport]">
                                @if (appUserService.hasPermission(permission.workingHoursReport)) {
                                    <li
                                        (click)="closeMenu()"
                                        routerLink="/working-hours-report"
                                        routerLinkActive="active"
                                    >
                                        <a class="icon nav-icon icon-myceres">{{
                                            'time-management.general.working-hours-report' | transloco
                                        }}</a>
                                    </li>
                                }
                            </ng-container>
                            <ng-container *ceresRequiredFeatures="[features.reporting.gmsReportingDemo]">
                                @if (appUserService.hasPermission(permission.reports)) {
                                    <li (click)="closeMenu()" routerLink="/gmsreporting" routerLinkActive="active">
                                        <a>{{ 'reporting.general.gms-reporting-title' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                            <ng-container *ceresRequiredFeatures="[features.reporting.productivityReport]">
                                @if (appUserService.hasPermission(permission.productivityReport)) {
                                    <li
                                        (click)="closeMenu()"
                                        routerLink="/reports/productivity-report"
                                        routerLinkActive="active"
                                    >
                                        <a>{{ 'reporting.general.productivity-title' | transloco }}</a>
                                    </li>
                                }
                            </ng-container>
                        </ul>
                    </li>
                }
            </ng-container>

            <!-- employee -->
            <ng-container *ceresRequiredFeatures="[features.employees.enabled]">
                @if (appUserService.hasPermission(permission.employee.readAll)) {
                    <li
                        [ngClass]="{
                            open: nestedNavigationMenus[4].open === true && isMenuOpen,
                            active: this.router.url.startsWith('/employees') && !isMenuOpen
                        }"
                    >
                        <a (click)="openSubMenu($event, 4)">
                            <i
                                class="icon a-icon a-icon--service"
                                [matTooltip]="isMenuOpen ? '' : ('employee.general.employee-title' | transloco)"
                                [matTooltipPosition]="'right'"
                                matTooltipClass="pwo-tooltip"
                            ></i
                            >{{ 'employee.general.employee-title' | transloco }}
                            <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
                            <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
                        </a>
                        <ul class="submenu" [ngClass]="{ open: nestedNavigationMenus[4].open === true }">
                            @if (
                                appUserService.isAdmin() ||
                                appUserService.isTeamLeader() ||
                                appUserService.isTeamLeaderCharging() ||
                                appUserService.isGroupLeader() ||
                                appUserService.isLearningArchitect() ||
                                appUserService.isSupport()
                            ) {
                                <li (click)="closeMenu()" routerLink="/employees" routerLinkActive="active">
                                    <a>{{ 'employee.general.employees-title' | transloco }}</a>
                                </li>
                            }
                            <ng-container *ceresRequiredFeatures="[features.employees.skills]">
                                <li (click)="closeMenu()" routerLink="/employees/skills" routerLinkActive="active">
                                    <a>{{ 'employee.general.skills-title' | transloco }}</a>
                                </li>
                            </ng-container>
                            <!-- <li>
<a>{{ 'employee.general.profile-title' | transloco }}</a>
</li> -->
                        </ul>
                    </li>
                }
            </ng-container>
            <!-- Confluence tool -->
            <ng-container *ceresRequiredFeatures="[features.faq.enabled]">
                <li>
                    <a
                        (click)="closeMenu()"
                        href="https://confluence.gms.siemens.cloud/display/CER/CERES+Knowledge+Base"
                        target="_blank"
                    >
                        <i
                            class="icon a-icon a-icon--chat"
                            [matTooltip]="'shared.miscellaneous.faq' | transloco"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="pwo-tooltip"
                        ></i
                        >{{ 'shared.miscellaneous.faq' | transloco }}
                    </a>
                </li>
            </ng-container>

            <!-- ticket tool -->
            <ng-container *ceresRequiredFeatures="[features.ticketTool.enabled]">
                <li>
                    <a
                        (click)="closeMenu()"
                        href="https://jira.gms.siemens.cloud/servicedesk/customer/portal/27"
                        target="_blank"
                    >
                        <i
                            class="icon a-icon a-icon--support"
                            [matTooltip]="'shared.miscellaneous.ticket-tool' | transloco"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="pwo-tooltip"
                        ></i
                        >{{ 'shared.miscellaneous.ticket-tool' | transloco }}
                    </a>
                </li>
            </ng-container>

            <!-- services -->
            <ng-container *ceresRequiredFeatures="[features.services.enabled]">
                @if (appUserService.hasPermission(permission.services.readAll)) {
                    <li [ngClass]="{ active: this.router.url.startsWith('/services') }">
                        <a
                            routerLink="/services"
                            routerLinkActive="active"
                            class="d-flex align-items-center"
                            (click)="closeMenu()"
                        >
                            <i
                                class="icon a-icon a-icon--shopping-cart"
                                [matTooltip]="isMenuOpen ? '' : ('services.general.title' | transloco)"
                                [matTooltipPosition]="'right'"
                                matTooltipClass="pwo-tooltip"
                            ></i
                            >{{ 'services.general.title' | transloco }}
                        </a>
                    </li>
                }
            </ng-container>

            <!-- admin -->
            <ng-container *ceresRequiredFeatures="[features.administration.enabled]">
                @if (appUserService.hasPermission(permission.admin.access)) {
                    <li [ngClass]="{ active: this.router.url.startsWith('/admin') }">
                        <a
                            routerLink="/admin"
                            routerLinkActive="active"
                            class="d-flex align-items-center"
                            (click)="closeMenu()"
                        >
                            <i
                                class="icon a-icon a-icon--settings"
                                [matTooltip]="isMenuOpen ? '' : ('admin.general.admin-title' | transloco)"
                                [matTooltipPosition]="'right'"
                                matTooltipClass="pwo-tooltip"
                            ></i
                            >{{ 'admin.general.admin-title' | transloco }}
                        </a>
                    </li>
                }
            </ng-container>
        </ul>
    </div>
</nav>
